/*
TABSQUARE SMARTPAY
URL: ?
AUTHOR: Nabil Amer Thabit (nbilz//lab//dsign | @nbilz)
EMAIL: nbilz@live.com
CREATE DATE: Oct 14, 2022
UPDATE DATE: Oct 14, 2022
REVISION: 1
NAME: variable.scss
TYPE: SASS Cascading Style Sheet
DESCRIPTION: SmartPay | ...
PALETTE:    1. #FF6B00 / rgb(255, 107, 0) (Pure Orange / Blaze Orange)
            2. #FFA800 / rgb(255, 168, 0) (Pure (or mostly pure) Orange / Web Orange)
            3. #589C00 / rgb(88, 156, 0) (Dark Green - Lime Green / Limeade)
            4. #0C8ED0 / rgb(12, 142, 208) (Strong Blue / Cerulean)
            5. #D10000 / rgb(209, 0, 0) (Strong Red / Guardsman Red)
*/

#root{
    // $colorPrimary: #FF6B00;
    // $colorPrimaryRGB: 255, 168, 0;
    // $colorPrimaryHover: #FFA800;
    // $colorSuccess: #00B25F;
    // $colorWarning: #FA8F0C;
    // $colorError: #D10000;
    // $colorBg: #EDEEF2;
    // $colorLabel: #7B7D8D;
    // $colorLabelRGB: 123, 125, 141;
    // $colorText: #24242E;
}

$colorPrimary: rgb(var(--colorPrimary));
$colorPrimaryRGB: var(--colorPrimary);
$colorPrimaryHover: rgb(var(--colorPrimaryHover));
$colorPrimaryHoverRGB: var(--colorPrimaryHover);
$colorSuccess: rgb(var(--colorSuccess));
$colorSuccessRGB: var(--colorSuccess);
$colorWarning: rgb(var(--colorWarning));
$colorWarningRGB: var(--colorWarning);
$colorError: rgb(var(--colorError));
$colorErrorRGB: var(--colorError);
$colorBg: rgb(var(--colorBg));
$colorBgRGB: var(--colorBg);
$colorLabel: rgb(var(--colorLabel));
$colorLabelRGB: var(--colorLabel);
$colorText: rgb(var(--colorText));
$colorTextRGB: var(--colorText);
$colorFb: rgb(var(--colorFb));
$colorIg: rgb(var(--colorIg));
$colorTw: rgb(var(--colorTw));

$colorScheme1: #C5ECFD;
$colorScheme2: #FFC7D6;
$colorScheme3: #DBD1FC;
$colorScheme4: #FFD9A2;
$colorScheme5: #A4FFA2;

$spacing: 16px;
$spacingMul125: calc($spacing * 1.25);
$spacingMul15: calc($spacing * 1.5);
$spacingMul2: calc($spacing * 2);
$spacingDiv125: calc($spacing / 1.25);
$spacingDiv15: calc($spacing / 1.5);
$spacingDiv2: calc($spacing / 2);
$spacingDiv4: calc($spacing / 4);
$spacingDiv6: calc($spacing / 6);
$spacingDiv8: calc($spacing / 8);
$radiusOut: 24px;
$radiusOutMul125: calc($radiusOut * 1.25);
$radiusIn: calc($radiusOut / 2);
$radiusInDiv125: calc($radiusIn / 1.25);
$radiusInDiv2: calc($radiusIn / 2);
$maxWidth: 500px;

// $primaryBorderColor: #CDE8E7;
// $primaryBorderColorHover: rgba(112, 112, 112, .5);
// $secondaryBorderColor: rgba(112, 112, 112, .15);
// $primaryBorder: 1px solid $primaryBorderColor;
// $secondaryBorder: 1px solid $secondaryBorderColor;

%transition{
    transition: all 200ms ease-in-out !important;
    -moz-transition: all 200ms ease-in-out !important;
    -webkit-transition: all 200ms ease-in-out !important;
}

%transitionAnim{
  transition: all 300ms ease-in-out !important;
  -moz-transition: all 300ms ease-in-out !important;
  -webkit-transition: all 300ms ease-in-out !important;
}

%transitionSlow{
    transition: all 400ms ease-in-out !important;
    -moz-transition: all 400ms ease-in-out !important;
    -webkit-transition: all 400ms ease-in-out !important;
}

// %transitionslow{
//     transition: all 500ms ease-in-out !important;
//     -moz-transition: all 500ms ease-in-out !important;
//     -webkit-transition: all 500ms ease-in-out !important;
// }

// %static{
//     position: static;
//     transform: none;
// }

%anim-enter{
    overflow: hidden;
    opacity: 0;
    max-height: 0;
    transform: scale(.9);
}

%anim-enter-active{
    opacity: 1;
    overflow: hidden;
    max-height: 50vh;
    transform: scale(1);
    @extend %transitionAnim;
}

%anim-enter-done{
    max-height: none;
}

%anim-exit{
    opacity: 1;
    max-height: 50vh;
    transform: scale(1);
}

%anim-exit-active{
    opacity: 0;
    max-height: 0;
    transform: scale(.9);
    @extend %transitionAnim;
}